import { TemplateBackstageLogoIcon } from '@backstage/plugin-home';
import { makeStyles } from '@material-ui/core/styles';
import { 
  GitLabIcon,
  JiraIcon } from '../../assets/CustomIcons';
import React from 'react';

export const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#7df3e1',
  },
}));

export const tools = [
  {
    url: 'https://gitlab.com/deimosdev/tooling/engineeringportal',
    label: 'Engineering Portal',
    icon: <GitLabIcon />,
  },
  {
    url: 'https://gitlab.com/deimosdev/tooling/engineeringportal/issues/new',
    label: 'Submit New Issue',
    icon: <GitLabIcon />,
  },
];