import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  errorApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import {
  graphQlBrowseApiRef,
  GraphQLEndpoints,
} from '@backstage-community/plugin-graphiql';
import { 
  graphQlVoyagerApiRef,
  GraphQLVoyagerEndpoints 
} from '@backstage-community/plugin-graphql-voyager';
import {
  VisitsWebStorageApi,
  visitsApiRef,
} from '@backstage/plugin-home';
import { gitlabAuthApiRef } from '@backstage/core-plugin-api';
import { cicdStatisticsApiRef } from '@backstage-community/plugin-cicd-statistics';
import { CicdStatisticsApiGitlab } from '@backstage-community/plugin-cicd-statistics-module-gitlab';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: cicdStatisticsApiRef,
    deps: { gitlabAuthApi: gitlabAuthApiRef },
    factory({ gitlabAuthApi }) {
      return new CicdStatisticsApiGitlab(gitlabAuthApi);
    },
  }),  
  createApiFactory({
    api: visitsApiRef,
    deps: {
      identityApi: identityApiRef,
      errorApi: errorApiRef
    },
    factory: ({ identityApi, errorApi }) => VisitsWebStorageApi.create({ identityApi, errorApi }),
  }),
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),  
  createApiFactory({
    api: graphQlBrowseApiRef,
    deps: { 
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef
    },
    // TODO: Fix this to use the correct fetcher: https://discord.com/channels/687207715902193673/687235481154617364/1253657037284315219
    factory: ({ discoveryApi, identityApi}) =>
      GraphQLEndpoints.from([{
        id: 'gitlab',
        title: 'GitLab',
        fetcher: async (params: any) => {
          const url = await discoveryApi.getBaseUrl('proxy').then(url => `${url}/gitlab/graphql`) 
          const token = await identityApi.getCredentials();
          return fetch(url, {
          method: 'POST',
            headers: { 
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ` + token.token,
            },
            body: JSON.stringify(params),
          }).then(res => res.json());
        }, 
      },
      {
        id: 'jira',
        title: 'Jira',
        fetcher: async (params: any) => {
          const url = await discoveryApi.getBaseUrl('proxy').then(url => `${url}/jira/graphql`) 
          const token = await identityApi.getCredentials();
          return fetch(url, {
          method: 'POST',
            headers: { 
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ` + token.token,
            },
            body: JSON.stringify(params),
          }).then(res => res.json());
        },
      }
    ]),
  }),
  createApiFactory({
    api: graphQlVoyagerApiRef,
    deps: { 
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef
     },
    factory: ({ discoveryApi, identityApi }) => {
      return GraphQLVoyagerEndpoints.from([
        {
          id: `graphql-voyager-gitlab`,
          title: 'GitLab',
          introspectionErrorMessage:
            'Unable to perform introspection, make sure you are on the correct environment.',
          introspection: async (query: any) => {
            const url = await discoveryApi.getBaseUrl('proxy').then(url => `${url}/gitlab/graphql`) 
            const token = await identityApi.getCredentials();
            const res = await fetch(url, {
              method: 'POST',
              body: JSON.stringify({ query }),
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + token.token
              },
            });
            return res.json();
          },
          voyagerProps: {
            hideDocs: true,
          },
        },
        {
          id: `graphql-voyager-jira`,
          title: 'Jira',
          introspectionErrorMessage:
            'Unable to perform introspection, make sure you are on the correct environment.',
          introspection: async (query: any) => {
            const url = await discoveryApi.getBaseUrl('proxy').then(url => `${url}/jira/graphql`) 
            const token = await identityApi.getCredentials();
            const res = await fetch(url, {
              method: 'POST',
              body: JSON.stringify({ query }),
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + token.token
              },
            });
            return res.json();
          },
          voyagerProps: {
            hideDocs: true,
          },
        },        
      ]);
    },
  }),
]
