/*
 * Copyright 2022 Avalia Systems SA
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { 
  DiscoveryApi, 
  IdentityApi } from '@backstage/core-plugin-api';
import {
  loader,
  Loader,
  LoaderOptions } from 'vega'; 
import jsonata from 'jsonata'

export enum VegaLibrary {
  VEGA,
  VEGA_LITE,
}

export type VegaWidgetDefinition = {
  title?: string;
  library: VegaLibrary;
  spec: string;
  type: 'graphql' | 'other';
  proxyPath?: string;
  parameters?: any;
  expression?: string;  
}

export interface GraphQLOptions {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
  proxyPath: string;
  parameters: any;
  expression?: string;
}

export class GraphQLLoader implements Loader {
  private loader: Loader;
  private options: GraphQLOptions;

  constructor(options: GraphQLOptions) {
    const loaderOptions: LoaderOptions = {
      "baseURL": "http://localhost:7007/api/proxy/gitlab/graphql",
      "defaultProtocol": "http:",
      "mode": "http",
    };
    this.loader = loader(loaderOptions);
    this.options = options;
  }
  async load(uri: string, options: LoaderOptions) {
    return this.http(uri, options);
  }
  async sanitize(uri: string, options: LoaderOptions) {
    return this.loader.sanitize(uri, options);
  }
  async http(uri: string, options: LoaderOptions) {
    const url = await this.options.discoveryApi.getBaseUrl('proxy').then(url => `${url}` + this.options.proxyPath) 
    const token = await this.options.identityApi.getCredentials();    

    return fetch(url, {
      method: 'POST',
      headers: { 
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ` + token.token,
      },
      body: JSON.stringify(this.options.parameters),
    })
    .then(async (res) => {
      if (!res.ok) throw res.status + '' + res.statusText;
      if (this.options.expression === undefined) return res.json();
      const expression = jsonata(this.options.expression);
      var json = await res.json();
      var result = await expression.evaluate(json);
      return result;
    }
  );
  }
  async file(filename: string) {
    return this.loader.file(filename);
  }
}

